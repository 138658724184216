import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import supabase from "../config/supabaseClient"

function Instructions() {
    // State to manage the modal's visibility
    const [isModalOpen, setModalOpen] = useState(false);
    const [integrationSecret, setIntegrationSecret] = useState('');

    // Handler to open and close the modal
    const toggleModal = (event) => {
        event.preventDefault();
        setModalOpen(!isModalOpen);
    }

    const handleSubmit = async () => {
        // Extract user from session
        const result = await supabase.auth.getSession();
        const userId = result?.data?.session?.user?.id;
    
        // Delete any records with the same user_id
        const { error: deleteError } = await supabase
            .from('user_integrations')
            .delete()
            .eq('user_id', userId);
    
        if (deleteError) {
            console.error("Error deleting existing integration secret:", deleteError);
        }
    
        // Insert new record (always execute, regardless of previous deletion outcome)
        const { data, error: insertError } = await supabase
            .from('user_integrations')
            .insert(
                { user_id: userId, key: integrationSecret }
            )
            .select('*');
    
        if (insertError) {
            console.error("Error inserting integration secret:", insertError);
        } else {
            console.log("Integration secret inserted:", data);
        }
    
        // Close the modal
        setModalOpen(false);
    }

    return (
        <div>
            <h2>Instructions</h2>
            
            <h4>Adding Your Integration</h4>
            <ol>
                <li><b>Sign-in</b> to your <a href="https://www.notion.so/login" target="_blank" rel="noopener noreferrer">Notion</a></li>
                <li>
                    Go to <b>My Integrations</b>:
                    <ul>
                        <li>
                            <a href="https://www.notion.so/my-integrations" target="_blank" rel="noopener noreferrer">https://www.notion.so/my-integrations</a>
                        </li>
                    </ul>
                </li>
                <li>Click '<b>Create new integration</b>'</li>
                <ul>
                    <li>Name your integration</li>
                    <ul>
                        <li>You can name it anything, or simply name it 'notion-shuffle'</li>
                    </ul>
                    <li>Click <b>Submit</b></li>
                </ul>
                <li>On the next page, <b>copy the integration secret and paste it </b> 
                    <a href="/EnterKey" onClick={toggleModal}>here</a>.
                </li>
            </ol>
            
            <h4>Adding Your Database(s)</h4>
            <ol>
                <li><b>Navigate to the database</b> that you would like to shuffle.</li>
                <li>Click the <b>three dots at the top right</b>.</li>
                <li>At the bottom, find <b>'Add connections'</b> and then <a href="https://www.dropbox.com/scl/fi/r4hc3btctahdlmmcw5nl7/NotionAddConnection.png?rlkey=krbv0t3w322dujlnc1v6oynw8&dl=0" target="_blank" rel="noopener noreferrer">connect your integration</a> that you created earlier.</li>
                <li><b>Copy </b> your <a href="https://www.dropbox.com/scl/fi/i0qxofu6549v61pa8zpff/NotionDatabaseID.png?rlkey=hsyduqgjhalh5xxhf9qnt5j7o&dl=0" target="_blank" rel="noopener noreferrer">Database ID</a> </li>
                <li><b>Add your Database ID and Database Name</b> <Link to="/AddDatabase">here</Link>.</li>
            </ol>

            <h4>Your Daily Email</h4>
            <ul>
                <li>Every day at a standard time, you will get an email in your inbox with 5 shuffled Notion Pages from each database that you've added.</li>
                <li>Each page will have a link to the original page in Notion</li>
                <li>You can toggle off emails for any of your databases under the Database(s) link above.</li>
                <li>Ability to customize your email schedule and content will be coming soon!</li>
            </ul>
        
            {/* Modal UI */}
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h4 className="modal-header">Enter Integration Secret</h4>
                        <input
                            className="modal-input"
                            type="text"
                            placeholder="Enter your secret"
                            value={integrationSecret}
                            onChange={(e) => setIntegrationSecret(e.target.value)}
                        />
                        <div className="modal-buttons">
                            <button onClick={toggleModal}>Close</button>
                            <button onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Instructions;
