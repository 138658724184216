import { useEffect, useState } from "react"
import { useParams, useNavigate } from 'react-router-dom'
import supabase from "../config/supabaseClient"

const UpdateIntegration = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [integrationName, setIntegrationName] = useState('')
  const [integrationKey, setIntegrationKey] = useState('')
  const [formError, setFormError] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!integrationName || !integrationKey) {
      setFormError('Please fill in all the fields correctly.');
      return;
    }

    // Check if the key starts with 'secret_'
    if (!integrationKey.startsWith('secret_')) {
      setFormError('Invalid Notion Integration Key. It should start with "secret_".');
      return;
    }

        // Get user_id from the Supabase session
        const result = await supabase.auth.getSession();
        const userId = result?.data?.session?.user?.id;

        // console.log("User Lookup Result:", result);
    
        if (!userId) {
          setFormError('User not found.')
          return
        }

    // Delete the record with the matching id
    const { error: deleteError } = await supabase
      .from('user_integrations')
      .delete()
      .eq('id', id);

    if (deleteError) {
        console.error("Error deleting existing integration:", deleteError);
    }

    // Insert the new record
    // const { data, error: insertError } = await supabase
    const { error: insertError } = await supabase
      .from('user_integrations')
      .insert({
          id: id,
          user_id: userId,
          integration_name: integrationName,
          key: integrationKey
      })
      .select('*')

    if (insertError) {
      setFormError('An error occurred. Please try again.');
    } else {
      setFormError(null);
      navigate('/YourIntegration');
    }
}

  useEffect(() => {
    const fetchIntegration = async () => {
      const { data, error } = await supabase
        .from('user_integrations')
        .select()
        .eq('id', id)
        .single()

      if (error) {
        navigate('/', { replace: true })
      }
      if (data) {
        setIntegrationName(data.integration_name)
        setIntegrationKey('************************************')
        // setIntegrationKey(data.key)
      }
    }

    fetchIntegration()
  }, [id, navigate])

  return (
    <div className="page create">
      <form onSubmit={handleSubmit}>
        <label htmlFor="integrationName">Integration Name:</label>
        <input 
          type="text" 
          id="integrationName"
          value={integrationName}
          onChange={(e) => setIntegrationName(e.target.value)}
        />

        <label htmlFor="integrationKey">Notion Integration Key:</label>
        <input 
          id="integrationKey"
          value={integrationKey}
          onChange={(e) => setIntegrationKey(e.target.value)}
        />

        <button>Update Integration</button>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  )
}

export default UpdateIntegration