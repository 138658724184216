import { useState } from "react"
import { useNavigate } from "react-router-dom"
import supabase from "../config/supabaseClient"

const AddDatabase = () => {
  const navigate = useNavigate()

  const [databaseName, setDatabaseName] = useState('')
  const [databaseId, setDatabaseId] = useState('')
  // const [titleColumnName, setTitleColumnName] = useState('')
  const [formError, setFormError] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!databaseName || !databaseId ) {
      // || !titleColumnName -- removing this requirement for now
      setFormError('Please fill in all the fields correctly.')
      return
    }

    const result = await supabase.auth.getSession();
    const userId = result?.data?.session?.user?.id;
    console.log(result);

    if(!userId) {
      console.log(userId)
      setFormError('Unable to get user information. Please log in again.')
      return;
    }

    const { data, error } = await supabase
      .from('user_databases')
      .insert([{ 
          user_id: userId,
          database_name: databaseName,
          // title_column_name: titleColumnName,
          notion_database_id: databaseId
      }])
      .select('*');

    if (error) {
      console.log(error)
      setFormError('Please fill in all the fields correctly.')
    }
    if (data) {
      console.log(data)
      setFormError(null)
      navigate('/YourDatabases')
    }
  }

  return (
    <div className="page create">
      <form onSubmit={handleSubmit}>
        <label htmlFor="databaseName">Database Name:</label>
        <input 
          type="text" 
          id="databaseName"
          value={databaseName}
          onChange={(e) => setDatabaseName(e.target.value)}
        />
        
        <label htmlFor="databaseId">Database ID:</label>
        <input 
          id="databaseId"
          value={databaseId}
          onChange={(e) => setDatabaseId(e.target.value)}
        />

        {/* <label htmlFor="titleColumnName">Title Column Name (case sensitive):</label>
        <input 
          type="text"
          id="titleColumnName"
          value={titleColumnName}
          onChange={(e) => setTitleColumnName(e.target.value)}
        /> */}

        <button>Add Notion Database</button>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  )
}

export default AddDatabase