import supabase from '../config/supabaseClient'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

// components
import IntegrationCard from '../components/IntegrationCard'

const YourIntegration = () => {
  const [fetchError, setFetchError] = useState(null)
  const [integrations, setIntegrations] = useState(null)
  const [orderBy, setOrderBy] = useState('created_at')

  const handleDelete = (id) => {
    setIntegrations(prevIntegrations => {
      return prevIntegrations.filter(key => key.id !== id)
    })
  }

  useEffect(() => {
    const fetchIntegrations = async () => {
      const result = await supabase.auth.getSession();
      const userId = result?.data?.session?.user?.id;
      // console.log(result);

      if(!userId) {
        setFetchError(<span>Please <Link to="/SignIn">Sign In</Link>.</span>);
        return;
      }
      
      const { data, error } = await supabase
        .from('user_integrations')
        .select()
        .eq('user_id', userId)
        .order(orderBy, {ascending: false})
        .select('*')
      
      if (error) {
        setFetchError('Could not fetch the integration')
        setIntegrations(null)
      }
      if (data) {
        setIntegrations(data)
        setFetchError(null)
      }
    }

    fetchIntegrations()

  }, [orderBy])

  return (
    <div className="page home">
      {fetchError && (<p>{fetchError}</p>)}
      {integrations && (
        <div className="integrations">
          <div className="integration-grid">
            {integrations.map(integration => (
              <IntegrationCard 
              key={integration.id}
              integration={integration}
              onDelete={handleDelete} 
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default YourIntegration