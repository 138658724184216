import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom"
import supabase from "./config/supabaseClient"

// pages
import SignIn from "./pages/SignIn"
import VerifyOTP from './components/VerifyOTP'
import Instructions from "./pages/Instructions"
import YourDatabases from "./pages/YourDatabases"
import YourIntegration from "./pages/YourIntegration"
import AddDatabase from "./pages/AddDatabase"
import UpdateDatabase from "./pages/UpdateDatabase"
import UpdateIntegration from "./pages/UpdateIntegration"

function ExternalRedirect({ to }) {
  useEffect(() => {
    window.location.replace(to);
  }, [to]);

  return null;
}

const handleLogout = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) {
    console.error("Error logging out:", error);
  }
};

function App() {
  return (
    <BrowserRouter>
      <nav>
        <h1>Notion Shuffle</h1>
        <Link to="/Instructions">Instructions</Link>
        <Link to="/YourIntegration">Connection</Link>
        <Link to="/YourDatabases">Database(s)</Link>
        <Link to="/AddDatabase">Add Database</Link>
        <a href="https://landing.notion-shuffle.com/" onClick={handleLogout} className="logout-link">Log Out</a>
      </nav>
      <Routes>
        <Route path="/" element={<ExternalRedirect to="https://landing.notion-shuffle.com" />} index />
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/VerifyOTP" element={<VerifyOTP />} />
        <Route path="/Instructions" element={<Instructions />} />
        <Route path="/YourIntegration" element={<YourIntegration />} />
        <Route path="/Integration-:id" element={<UpdateIntegration />} />
        <Route path="/YourDatabases" element={<YourDatabases />} />
        <Route path="/AddDatabase" element={<AddDatabase />} />
        <Route path="/:id" element={<UpdateDatabase />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;