import { useEffect, useState } from "react"
import { useParams, useNavigate } from 'react-router-dom'
import supabase from "../config/supabaseClient"

const UpdateDatabase = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [databaseName, setDatabaseName] = useState('')
  const [databaseId, setDatabaseId] = useState('')
  // const [titleColumnName, setTitleColumnName] = useState('')
  const [isActive, setIsActive] = useState('')
  const [formError, setFormError] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!databaseName || !databaseId ) {
      // || !titleColumnName -- removing this requirement for now
      setFormError('Please fill in all the fields correctly.')
      return
    }

    const { data, error } = await supabase
      .from('user_databases')
      .update([{ 
          database_name: databaseName,
          // title_column_name: titleColumnName,
          notion_database_id: databaseId,
          is_active: isActive
      }])
      .eq('id', id)
      .select('*')

    if (error) {
      setFormError('Please fill in all the fields correctly.')
    }
    if (data) {
      setFormError(null)
      navigate('/YourDatabases')
    }
  }

  useEffect(() => {
    const fetchDatabase = async () => {
      const { data, error } = await supabase
        .from('user_databases')
        .select()
        .eq('id', id)
        .single()

      if (error) {
        navigate('/YourDatabases', { replace: true })
      }
      if (data) {
        setDatabaseName(data.database_name)
        setDatabaseId(data.notion_database_id)
        // setTitleColumnName(data.title_column_name)
        setIsActive(data.is_active)
      }
    }

    fetchDatabase()
  }, [id, navigate])

  return (
    <div className="page create">
      <form onSubmit={handleSubmit}>
        <label htmlFor="databaseName">Database Name:</label>
        <input 
          type="text" 
          id="databaseName"
          value={databaseName}
          onChange={(e) => setDatabaseName(e.target.value)}
        />

        <label htmlFor="databaseId">Database ID:</label>
        <input 
          id="databaseId"
          value={databaseId}
          onChange={(e) => setDatabaseId(e.target.value)}
        />

        {/* <label htmlFor="titleColumnName">Title Column Name:</label>
        <input 
          type="text"
          id="titleColumnName"
          value={titleColumnName}
          onChange={(e) => setTitleColumnName(e.target.value)}
        /> */}

        <div className="checkbox-container">
        <label htmlFor="isActive">Receive daily email?</label>
        <input 
          type="checkbox"
          id="isActive"
          checked={isActive}
          onChange={(e) => setIsActive(e.target.checked)}
        />
        </div>

        <button>Update Database</button>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  )
}

export default UpdateDatabase