import supabase from "../config/supabaseClient"
import { Link } from 'react-router-dom'

const DatabaseCard = ({ database, onDelete }) => {

  const handleDelete = async () => {
    const { data, error } = await supabase
      .from('user_databases')
      .delete()
      .eq('id', database.id)
      .select('*');
    
    if (error) {
      console.log(error)
    }
    if (data) {
      console.log(data)
      onDelete(database.id)
    }
  }

  return (
    <div className="database-card">
      <h3>{database.database_name}</h3>
      <p>{database.notion_database_id}</p>
      <div className="rating">
                {database.is_active ? '✓' : '☐'}
      </div>
      <div className="buttons">
        <Link to={"/" + database.id}>
          <i className="material-icons">edit</i>
        </Link>
        {/* <i className="material-icons" onClick={handleDelete}>delete</i> */}
      </div>
    </div>
  )
}

export default DatabaseCard