import supabase from "../config/supabaseClient"
import { Link } from 'react-router-dom'

const IntegrationCard = ({ integration, onDelete }) => {

  const handleDelete = async () => {
    const { data, error } = await supabase
      .from('user_integrations')
      .delete()
      .eq('id', integration.id)
    
    if (error) {
      console.log(error)
    }
    if (data) {
      console.log(data)
      onDelete(integration.id)
    }
  }

  return (
    <div className="integration-card">
      <h3>Your Notion Integration</h3>
      <p>Click edit icon to View</p>
      <div className="buttons">
        <Link to={"/integration-" + integration.id}>
          <i className="material-icons">edit</i>
        </Link>
        {/* <i className="material-icons" onClick={handleDelete}>delete</i> */}
      </div>
    </div>
  )
}

export default IntegrationCard