// VerifyOTP.js

import { useState } from 'react';
import supabase from '../config/supabaseClient';
import { useNavigate } from 'react-router-dom';

const VerifyOTP = () => {
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const navigate = useNavigate();

    const handleVerification = async (event) => {
        event.preventDefault();

        const userEmail = localStorage.getItem('user_email');

        setLoading(true);
        const { error } = await supabase.auth.verifyOtp({ 
            email: userEmail,
            token: code, 
            type: 'email'
        });

        if (error) {
            alert(error.error_description || error.message);
        } else {
            alert('Successfully verified!');
            navigate('/YourDatabases');
        }
        setLoading(false);
    };

    return (
        <div>
            <h1>Verify OTP</h1>
            <form onSubmit={handleVerification}>
                <label>
                    Enter Your Sign In Code:
                    <input type="text" value={code} onChange={(e) => setCode(e.target.value)} />
                </label>
                <button type="submit" disabled={loading}>
                    {loading ? 'Verifying...' : 'Submit'}
                </button>
            </form>
        </div>
    );
};

export default VerifyOTP;
