// SignIn.js
import { useState } from 'react';
import supabase from '../config/supabaseClient';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    setLoading(true);
    const { error } = await supabase.auth.signInWithOtp({ email });

    if (error) {
      alert(error.error_description || error.message);
  } else {
      localStorage.setItem('user_email', email); // Store the email to local storage
      alert('Check your email for a sign-in code!');
      navigate('/VerifyOTP');
  }
   
  setLoading(false);
  
  };

  return (
    <div>
      <h1>Sign Up / Sign In</h1>
      <form onSubmit={handleLogin}>
        <label>
          Email:
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} />
        </label>
        <button type="submit" disabled={loading}>
          {loading ? 'Loading...' : 'Sign In'}
        </button>
      </form> 
    </div>
  );
};

export default SignIn;
