import supabase from '../config/supabaseClient'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

// components
import DatabaseCard from '../components/DatabaseCard'

const YourDatabases = () => {
  const [fetchError, setFetchError] = useState(null)
  const [databases, setDatabases] = useState(null)
  const [orderBy, setOrderBy] = useState('created_at')

  const handleDelete = (id) => {
    console.log("onDelete called with ID:", id)
    setDatabases(prevDatabases => {
      return prevDatabases.filter(db => db.id !== id)
    })
  }

  useEffect(() => {
    const fetchDatabases = async () => {
      const result = await supabase.auth.getSession();
      const userId = result?.data?.session?.user?.id;
      // console.log(result);

      if(!userId) {
        setFetchError(<span>Please <Link to="/SignIn">Sign In</Link>.</span>);
        return;
      }

      const { data, error } = await supabase
        .from('user_databases')
        .select('*')
        .eq('user_id', userId)
        .order(orderBy, {ascending: false})
        .select('*')
      
      if (error) {
        setFetchError('Could not fetch the databases')
        setDatabases(null)
      }
      if (data) {
        setDatabases(data)
        setFetchError(null)
      }
    }
    fetchDatabases()}, [orderBy])

  return (
    <div className="page home">
      {fetchError && (<p>{fetchError}</p>)}
      {databases && (
        <div className="databases">
          <div className="order-by">
            <p>Order by:</p>
            <button onClick={() => setOrderBy('database_name')}>Database Name</button>
            <button onClick={() => setOrderBy('created_at')}>Time Created</button>
          </div>
          <div className="database-grid">
            {databases.map(database => (
              <DatabaseCard 
              key={database.id}
              database={database}
              onDelete={handleDelete} 
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default YourDatabases